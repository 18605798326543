<ul class="idea-list list-group">
	<li *ngFor="let item of ideas"
		class="list-group-item"
		[style.backgroundColor]="getLightBgColor(item.gliphColor)"
		routerLinkActive="active">
		<a
			[routerLinkActiveOptions]="{exact: true}"
			[routerLink]="urlService.navigateToIdea(item.luogoID)">
			<span class="idea-icon"
				[style.backgroundColor]="getLightBgColor(item.gliphColor)"
				[ngClass]="getIcon(item.gliphName)"></span>
			<span class="idea-nome">{{ item.nome }}</span>
		</a>
	</li>
</ul>
