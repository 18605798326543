import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TripCardComponent } from './components/trip-card/trip-card.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { DestinationCardComponent } from './components/destination-card/destination-card.component';
import { DestinationsListComponent } from './components/destinations-list/destinations-list.component';
import { IdeasListComponent } from './components/ideas-list/ideas-list.component';
import { BannerCardComponent } from './components/banner-card/banner-card.component';
import { InfoStripComponent } from './components/info-strip/info-strip.component';
import { NrFlagsComponent } from './components/nr-flags/nr-flags.component';
import { AppRoutingModule } from '../app-routing.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RibbonComponent } from './components/ribbon/ribbon.component';

@NgModule({
	declarations: [
		TripCardComponent,
		BasePageComponent,
		DestinationCardComponent,
		DestinationsListComponent,
		IdeasListComponent,
		BannerCardComponent,
		InfoStripComponent,
		NrFlagsComponent,
		RibbonComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		AppRoutingModule,
		NgbDropdownModule
	],
	exports: [
		TripCardComponent,
		DestinationCardComponent,
		DestinationsListComponent,
		IdeasListComponent,
		BannerCardComponent,
		InfoStripComponent,
		NrFlagsComponent,
		RibbonComponent
	]
})
export class SharedModule { }
