import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {BackendService, BaseDataService, GetTripResponse, IGetTripRequest, TripInfo} from 'nr-common';
import {WebsiteService} from './website.service';

/**
 * SERVIZIO PER LA GESTIONE DEI VIAGGI
 * --------------------------------------------------------------
 * Per abbattere il numero di chiamate al server.
 * Mantiene in cache locale:
 *	- l'insieme dei viaggi visitati
 *	- dettagli dei singoli viaggi in lazy load
 */
const SINGLE_ENDPOINT = 'b2c/trip';
const PLURAL_ENDPOINT = 'b2c/trips';

@Injectable({
	providedIn: 'root'
})
export class TripService {
	// tslint:disable-next-line:variable-name
	private cache_trips$: Observable<TripInfo>[] = [];

	constructor(
		private backend: BackendService,
		public website: WebsiteService,
		private baseData: BaseDataService
	) { }

	// TODO getTrip()
	// TODO getDestinationTrips()
	// TODO getIdeasTrips()

	/**
	 * Recupera i dati del viaggio
	 * @returns Observable<TripInfo[]>
	 */
	public getTripInfo(tripId: number): Observable<TripInfo> {
		if (!this.cache_trips$[tripId]) {
			try {
				const localRequest: IGetTripRequest = {
					siteID: this.website.currentWebsite.id,
					lingua: this.baseData.getLingua(),
					tripID: tripId
				};

				this.cache_trips$[tripId] = this
					.backend
					.call('b2c/trip', localRequest)
					.pipe(
						map((data: GetTripResponse) => {
							return data.trip;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_trips$[tripId];
	}
}
