<div class="info-strip"
	[class.navbar-solid]="hide">
	<div class="container">
		<nav class="navbar-expand-lg">
			<ul class="navbar-nav">
				<li class="nav-item" *ngFor="let item of menuVoices">
					<a routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: false}"
					[routerLink]="item.url">{{ item.nome }}</a>
				</li>
				<ng-container *ngIf="user.currentLang$|async as lang">
					<li class="nav-spacer">&nbsp;</li>
					<li class="nav-item">
						<a [class.active]="lang=='it'" [title]="'LANGUAGES.IT'|translate" (click)="changeLang('it', $event)" href="#">{{'LANGUAGES.IT'|translate}}</a>
						<span class="vertical-spacer">&nbsp;|&nbsp;</span>
						<a [class.active]="lang=='fr'" [title]="'LANGUAGES.FR'|translate" (click)="changeLang('fr', $event)" href="#">{{'LANGUAGES.FR'|translate}}</a>
                        <span class="vertical-spacer">&nbsp;|&nbsp;</span>
                        <a [class.active]="lang=='de'" [title]="'LANGUAGES.DE'|translate" (click)="changeLang('de', $event)" href="#">{{'LANGUAGES.DE'|translate}}</a>
                        <span class="vertical-spacer">&nbsp;|&nbsp;</span>
                        <a [class.active]="lang=='nl'" [title]="'LANGUAGES.NL'|translate" (click)="changeLang('nl', $event)" href="#">{{'LANGUAGES.NL'|translate}}</a>
					</li>
				</ng-container>
			</ul>
		</nav>
	</div>
</div>
