<div class="trip-card-box card-box-a">
	<div class="img-box-a">
		<img class="img-a img-fluid"
			[alt]="trip.title"
			[src]="trip.imgUrl">
	</div>
	<div class="card-overlay">
		<div class="card-overlay-a-content">
			<div class="card-header-a">
				<h2 class="card-title-a">
					<a [routerLink]="urlService.navigateToTrip(trip.id)">
						{{ trip.title }}
					</a>
				</h2>
			</div>
			<div class="card-body-a">
				<div class="price-box">
					<span class="price-a">{{ 'COMPONENTS.TRIPCARD.PREZZO' | translate }}  {{ trip.price |
						currency:'EUR':'symbol':'4.0-0' }}
					</span>
				</div>
				<!-- <a [routerLink]="'/'" class="link-a">{{ 'COMPONENTS.TRIPCARD.VIEW-LINK' | translate }}
					<span class="ion-ios-arrow-forward"></span>
				</a> -->
			</div>
			<div class="card-footer-a">
				<ul class="card-info d-flex justify-content-around">
					<li>
						<span class="card-info-title">{{ 'COMPONENTS.TRIPCARD.DETAILS.Giorni' | translate }} {{ trip.days }}</span>
					</li>
					<li>
						<span class="card-info-title">{{ 'COMPONENTS.TRIPCARD.DETAILS.Notti' | translate }} {{ trip.nights }}</span>
					</li>
					<li>
						<span class="card-info-title">{{ 'COMPONENTS.TRIPCARD.DETAILS.Lingua' | translate }} {{ trip.language }}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
