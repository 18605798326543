import {
	Component,
	OnInit
} from '@angular/core';
import { ApiService }        from '../../services/api.service';
import { WebsiteService }    from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import { Title }             from '@angular/platform-browser';
import { TranslateService }  from '@ngx-translate/core';
import { Destination } 		 from 'nr-common';


@Component({
	selector: 'nr-destinationspage',
	templateUrl: './destinationspage.component.html',
	styleUrls: ['./destinationspage.component.scss']
})
export class DestinationspageComponent extends BasePageComponent implements OnInit {
	public destinations: Destination[];

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService,
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'destinations';
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.apiService.getDestinations().subscribe((data) => {
			this.destinations = data;
		});
	}
}
