<div class="openDestination.destinazione-card-box card"
	[attr.data-id]="openDestination.destinazione.luogoID">
	<img class="card-img-top rounded-0"
		[alt]="openDestination.destinazione.nome"
		[src]="getCoverImage(openDestination.destinazione.coverImageUrl)">
	<h5 class="destination-name"
		[style.backgroundColor]="getColor(openDestination.destinazione.gaBgColor)">
		<div class="card-icon">
			<i [ngClass]="getIcon(openDestination.destinazione.gaIcon)"></i>
		</div>
		<div class="card-name" [style.color]="invertedColor">{{ transformName(openDestination.destinazione.nome) }}</div>
	</h5>
	<div class="card-body">
		<div *ngFor="let article of getArticlesOrderByTitle(openDestination.articles)">
			<!-- <a target="_blank" [href]="getArticleUrl(article.id)">{{ article.title }}</a> -->
			<a	[routerLink]="navigateToOpenDestination(article.id)">{{ getArticleTitle(article.title) }}</a>
		</div>
	</div>
</div>
