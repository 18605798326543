import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AerialViewManagerService } from '../services/managers/aerial-view-manager.service';
import { AerialMetadataFormatEnum } from '../models/aerial-view.model';
import { NrGeocoderService } from '../services/geocoder-service';
import { finalize } from 'rxjs/operators';

export enum NrAerialQualitiEnum {
    High,
    Medium,
    Low
}

@Component({
    selector: 'nr-mas-aerial-view',
    template: `
        <video width="100%" height="100%" 
                [poster]="videoImage" 
                [src]="videoSource"
                loop="true"
                autoplay="true"
                muted="true">
            Your browser does not support HTML video.
        </video>
    `,
    styles: [
        `
        
        `
    ]
})
export class NrAerialView implements OnInit, AfterViewInit, OnDestroy {
    
    @Input() latitude: number;
    @Input() longitude: number;
    @Input() quality: NrAerialQualitiEnum = NrAerialQualitiEnum.Medium;
    @Input() loop = false;

    public videoImage: string;
    public videoSource: string;

    constructor(private aerialViewManager: AerialViewManagerService,
                private geocoderService: NrGeocoderService) { }

    ngOnInit() { }

    ngAfterViewInit() {

        const latLng: google.maps.LatLngLiteral = { lat: this.latitude, lng: this.longitude };
        const sub = this.geocoderService.geocode({ location: latLng }).pipe(
                finalize(() => sub.unsubscribe())
            )
            .subscribe(geocodedAddress => {
                if(geocodedAddress == null || geocodedAddress.length === 0) return;
                const address = geocodedAddress[0].formatted_address;

                let videoFormat = AerialMetadataFormatEnum.Mp4High;
                switch(this.quality) {
                    case NrAerialQualitiEnum.Medium:
                        videoFormat = AerialMetadataFormatEnum.Mp4Medium;
                        break;
                    case NrAerialQualitiEnum.Low:
                        videoFormat = AerialMetadataFormatEnum.Mp4Low;
                        break;
                    case NrAerialQualitiEnum.High:
                    default:
                        videoFormat = AerialMetadataFormatEnum.Mp4High;
                        break;
                }

                this.aerialViewManager.getAeriaView(address, videoFormat)
                    .then(
                        videoSource => {
                            this.videoSource = videoSource.videoLink;
                            this.videoImage = videoSource.videoPoster;
                        }, 
                        error => console.error(error)
                    );
            });        
    }

    ngOnDestroy() { }

}
