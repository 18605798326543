<ng-container *ngIf="(banner.bannerType === eBannerType.Offerta) then tmpOfferta else tmpBanner">
</ng-container>

<ng-template #tmpOfferta>
	<a class="card-link"
		[routerLink]="urlService.navigateToTrip(banner.id)"
		[attr.data-rem]="banner.id">
		<div class="card card-custom">
			<img class="card-img-top rounded-0"
				[alt]="banner.title"
				[src]="banner.imgUrl">
			<h5 class="destination-name"
				[style.backgroundColor]="getBgColor(banner.destination.gaBgColor)">
				<div class="card-icon">
					<i [ngClass]="getIcon(banner.destination.gaIcon)"></i>
				</div>
				<div class="card-name"
					[style.color]="invertedColor">{{ transformName(banner.destination.nome) }}</div>
			</h5>
			<div class="card-body">
				<div class="card-title">{{ banner.title }}</div>
				<div class="card-text">
					<p [innerHTML]="banner.sunto">&nbsp;</p>
				</div>
			</div>
		</div>
	</a>
</ng-template>

<ng-template #tmpBanner>
	<div class="card card-custom card-banner"
		[attr.data-rem]="banner.id">
		<div class="card-body"
			[innerHtml]="getSunto(banner.sunto)">
		</div>
		<!-- <div class="card-ribbon" *ngIf="hasRibbon(banner)">
			<nr-ribbon [fileType]="getRibbonType(banner)"></nr-ribbon>
		</div> -->
	</div>
</ng-template>
