import { Inject, Injectable, InjectionToken, LOCALE_ID, Optional } from '@angular/core';

import { DocumentRef, WindowRef } from './browser-globals';

import { Loader } from '@googlemaps/js-api-loader';

/**
 * Token for the config of the LazyMapsAPILoader. Please provide an object of type {@link MapsAPILoaderConfig}.
 */
export const MAPS_API_CONFIG = new InjectionToken<MapsAPILoaderConfig>('nr-maps MAPS_API_CONFIG');

/**
 * Configuration for the {@link MapsAPILoader}.
 */
export interface MapsAPILoaderConfig {
  /**
   * The Google Maps API Key (see:
   * https://developers.google.com/maps/documentation/javascript/get-api-key)
   */
  apiKey?: string;

  /**
   * The Google Maps client ID (for premium plans).
   * When you have a Google Maps APIs Premium Plan license, you must authenticate
   * your application with either an API key or a client ID.
   * The Google Maps API will fail to load if both a client ID and an API key are included.
   */
  clientId?: string;

  /**
   * The Google Maps channel name (for premium plans).
   * A channel parameter is an optional parameter that allows you to track usage under your client
   * ID by assigning a distinct channel to each of your applications.
   */
  channel?: string;

  /**
   * The default bias for the map behavior is US.
   * If you wish to alter your application to serve different map tiles or bias the
   * application, you can overwrite the default behavior (US) by defining a `region`.
   * See https://developers.google.com/maps/documentation/javascript/basics#Region
   */
  region?: string;

  /**
   * The Google Maps API uses the browser's preferred language when displaying
   * textual information. If you wish to overwrite this behavior and force the API
   * to use a given language, you can use this setting.
   * See https://developers.google.com/maps/documentation/javascript/basics#Language
   */
  language?: string;
}

@Injectable()
export class MapsAPILoader {
    protected _scriptLoadingPromise: Promise<void>;
    protected _config: MapsAPILoaderConfig;
    protected _windowRef: WindowRef;
    protected _documentRef: DocumentRef;
    protected readonly _SCRIPT_ID: string = 'nrGoogleMapsApiScript';

    private loader: Loader;

    constructor(@Optional() @Inject(MAPS_API_CONFIG) 
                config: any = null, 
                w: WindowRef, 
                d: DocumentRef,
                @Inject(LOCALE_ID) private localeId: string) {
        this._config = config || {};
        this._windowRef = w;
        this._documentRef = d;
    }

    load(): Promise<any> {
        this.loader = new Loader({
            apiKey: this._config.apiKey,
            version: 'weekly',
            client: this._config.clientId,
            channel: this._config.channel,
            id: this._SCRIPT_ID,
            region: this._config.region,
            language: this._config.language || (this.localeId !== 'en-US' ? this.localeId : null),
        });

        return this.loader.importLibrary('maps');
    }
}
