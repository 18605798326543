import { Directive, Input } from "@angular/core";

export type ControlPosition = keyof typeof google.maps.ControlPosition;

@Directive()
export abstract class NrMapControl {
    @Input() position: ControlPosition;
    abstract getOptions(): Partial<google.maps.MapOptions>;
}

@Directive({
    selector: 'nr-maps nr-maps-fullscreen-control',
    providers: [{ provide: NrMapControl, useExisting: NrFullscreenControl }],
})
export class NrFullscreenControl extends NrMapControl {
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            fullscreenControl: true,
            fullscreenControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
            },
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-type-control',
    providers: [{ provide: NrMapControl, useExisting: NrMapTypeControl }],
})
export class NrMapTypeControl extends NrMapControl {
    @Input() mapTypeIds: (keyof typeof google.maps.MapTypeId)[];
    @Input() style: keyof typeof google.maps.MapTypeControlStyle;

    getOptions(): Partial<google.maps.MapOptions> {
        return {
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
                style: this.style && google.maps.MapTypeControlStyle[this.style],
                mapTypeIds: this.mapTypeIds && this.mapTypeIds.map(mapTypeId => google.maps.MapTypeId[mapTypeId]),
            },
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-pan-control',
    providers: [{ provide: NrMapControl, useExisting: NrPanControl }],
})
export class NrPanControl extends NrMapControl {
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            panControl: true,
            panControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
            },
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-rotate-control',
    providers: [{ provide: NrMapControl, useExisting: NrRotateControl }],
})
export class NrRotateControl extends NrMapControl {
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            rotateControl: true,
            rotateControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
            },
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-scale-control',
    providers: [{ provide: NrMapControl, useExisting: NrScaleControl }],
})
export class NrScaleControl extends NrMapControl {
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            scaleControl: true,
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-street-view-control',
    providers: [{ provide: NrMapControl, useExisting: NrStreetViewControl }],
})
export class NrStreetViewControl extends NrMapControl {
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            streetViewControl: true,
            streetViewControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
            },
        };
    }
}

@Directive({
    selector: 'nr-maps nr-maps-zoom-control',
    providers: [{ provide: NrMapControl, useExisting: NrZoomControl }],
})
export class NrZoomControl extends NrMapControl {
    //@Input() style: keyof typeof google.maps.ZoomControlStyle;
    getOptions(): Partial<google.maps.MapOptions> {
        return {
            zoomControl: true,
            zoomControlOptions: {
                position: this.position && google.maps.ControlPosition[this.position],
                /* style: this.style && google.maps.ZoomControlStyle[this.style], */

            },
        };
    }
}