import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Languages, MenuData, UserService} from 'nr-common';
import {ApiService} from '../../../services/api.service';

const MIN_SCROLL_THRESHOLD = 50;

@Component({
	selector: 'nr-info-strip',
	templateUrl: './info-strip.component.html',
	styleUrls: ['./info-strip.component.scss'],
})
export class InfoStripComponent implements OnInit {
	public hide: boolean;
	public menuVoices: MenuData[];

	constructor(
		private apiService: ApiService,
		public translate: TranslateService,
		public user: UserService
	) {
	}

	ngOnInit(): void {
		this.apiService.getInfostrip().subscribe((data: MenuData[]) => {
			this.menuVoices = data;
		});
	}

	@HostListener('window:scroll', ['$event'])
	handleScroll() {
		const windowScroll = window.pageYOffset;
		//this.hide = (windowScroll >= MIN_SCROLL_THRESHOLD);
	}

	public async changeLang(lang: Languages, e: Event) {
		this.translate.use(lang);
		e?.preventDefault();
		await this.user.setLang(lang);
		// window.location.reload();
		window.location.href = this.apiService.takeMeHome(lang);
	}
}
