<section class="intro-single"></section>
<section data-rem="article">
	<div class="container">
		<!-- <article *ngIf="article.title"><h1>{{ article.title }}</h1></article> -->
		<article *ngIf="article"
			class="article-body"
			[innerHtml]="article"></article>
		<!-- <ng-container *ngIf="extraArticle">
			<div class="alert alert-primary">{{ extraArticle }}</div>
		</ng-container> -->
	</div>
</section>
