<div class="destination-card-box card">
	<img class="card-img-top rounded-0" [alt]="destination.nome" [src]="destination.coverImageUrl">
	<h5 class="destination-name"
		[style.backgroundColor]="getColor(destination.gaBgColor)">
		<div class="card-icon">
			<i [ngClass]="getIcon(destination.gaIcon)"></i>
		</div>
		<div class="card-name"
			[style.color]="invertedColor">{{ transformName(destination.nome) }}</div>
	</h5>
	<div class="card-body">
		<p class="card-text" [innerHTML]="endSoftly(destination.abstract)">&nbsp;</p>
		<a class="btn btn-b"
			[routerLink]="urlService.navigateToDestination(destination.luogoID)">{{ 'COMMON.DETTAGLI' | translate }}</a>
	</div>
</div>
