/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
	BackendService,
	BaseDataService,
	Destination,
	DestinationInfo,
	GetDestinationResponse,
	GetDestinationsResponse,
	GetOpenDestinationsResponse,
	IGetDestinationRequest,
	ISiteRequest,
	OpenDestination
} from 'nr-common';
import {WebsiteService} from './website.service';

/**
 * SERVIZIO PER LA GESTIONE DELLE DESTINAZIONI
 * --------------------------------------------------------------
 * Per abbattere il numero di chiamate al server.
 * Mantiene in cache locale:
 *	- l'insieme delle destinazioni
 *	- dettagli delle singole destinazioni in lazy load
 */
const SINGLE_ENDPOINT = 'b2c/destination';
const PLURAL_ENDPOINT = 'b2c/destinations';
const OPENDESTINATIONS_ENDPOINT = 'b2c/opendestinations';

@Injectable({
	providedIn: 'root'
})
export class DestinationService {
	private cache_destinations$: Observable<Destination[]> = null;
	private cache_destinationInfo$: Observable<DestinationInfo>[] = [];
	private cache_openDestinations$: Observable<OpenDestination[]> = null;
	private siteRequest: ISiteRequest;	// SiteRequest usati per chiamate server

	constructor(
		private backend: BackendService,
		public website: WebsiteService,
		private baseData: BaseDataService
	) {
		this.siteRequest = {
			siteID: this.website.currentWebsite.id,
			lingua: this.baseData.getLingua()
		};
	}

	/**
	 * Recupera i dati delle destinazioni
	 * @returns Observable<Destination[]>
	 */
	public getDestinations(): Observable<Destination[]> {
		if (this.cache_destinations$ == null) {
			try {
				this.cache_destinations$ = this.backend
					.call(PLURAL_ENDPOINT, this.siteRequest)
					.pipe(
						map((data: GetDestinationsResponse) => {
							return data.destinazioni;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_destinations$;
	}

	public getDestinationInfo(destinationId: number): Observable<DestinationInfo> {
		if (!this.cache_destinationInfo$[destinationId]) {
			try {
				const localRequest: IGetDestinationRequest = {
					siteID: this.website.currentWebsite.id,
					lingua: this.baseData.getLingua(),
					destinationID: destinationId
				};

				this.cache_destinationInfo$[destinationId] = this
					.backend
					.call(SINGLE_ENDPOINT, localRequest)
					.pipe(
						map((data: GetDestinationResponse) => {
							return data.destination;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_destinationInfo$[destinationId];
	}

	/**
	 * Recupera i dati delle destinazioni
	 * @returns Observable<Destination[]>
	 */
	public getOpenDestinations(): Observable<OpenDestination[]> {
		if (this.cache_openDestinations$ == null) {
			try {
				this.cache_openDestinations$ = this.backend
					.call(OPENDESTINATIONS_ENDPOINT, this.siteRequest)
					.pipe(
						map((data: GetOpenDestinationsResponse) => {
							return data.destinazioni;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_openDestinations$;
	}

	public getOpenDestination(openDestinationId: number): OpenDestination {
		let od: OpenDestination;
		this.getOpenDestinations().subscribe((data) => {
			od =  data[openDestinationId];
		});
		return od;
	}
}
