<ul class="destination-list list-group">
	<li *ngFor="let item of destinations"
		class="list-group-item"
		[style.backgroundColor]="getLightBgColor(item.gaBgColor)"
		routerLinkActive="active">
		<a
			[routerLinkActiveOptions]="{exact: true}"
			[routerLink]="urlService.navigateToDestination(item.luogoID)">
			<span class="dest-icon"
				[style.backgroundColor]="getLightBgColor(item.gaBgColor)">
				<i [ngClass]="getIcon(item.gaIcon)"></i>
			</span>
			<span class="dest-nome">{{ transformName(item.nome) }}</span>
		</a>
	</li>
</ul>
