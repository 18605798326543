<div class="card login-scenario">
	<img class="card-img-top rounded-0" [src]="mainSlide.img">
	<form (ngSubmit)="doLogin()" #frmLogin="ngForm">
		<div class="card-body">
			<div class="form-group row">
				<label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm text-right">{{ 'LOGIN.EMAIL' | translate}}</label>
				<div class="col-sm-9">
					<input type="email" class="form-control form-control-sm"
						id="colFormLabelSm" name="username"
						#tbUsername="ngModel"
						[disabled]="working"
						[(ngModel)]="loginUserData.username" required
						placeholder="{{ 'LOGIN.EMAIL' | translate}}">
					<div class="text-danger" *ngIf="submitted && tbUsername.invalid">{{ 'LOGIN.UTENTE-OBBLIGATORIO'|translate }}</div>
				</div>
			</div>
			<div class="form-group row">
				<label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm text-right">{{ 'LOGIN.PASSWORD' | translate }}</label>
				<div class="col-sm-9">
					<input type="password" class="form-control form-control-sm"
						id="inputSigninPassword" name="password"
						[disabled]="working"
						#tbPassword="ngModel"
						[(ngModel)]="loginUserData.password" required
						placeholder="{{ 'LOGIN.PASSWORD' | translate }}">
						<div class="text-danger" *ngIf="submitted && tbPassword.invalid">{{ 'LOGIN.PASSWORD-OBBLIGATORIA'|translate }}</div>
				</div>
			</div>
			<div class="row">
				<div class="col text-right">
					<button type="submit" class="btn btn-b-n"
						name="button"
						[disabled]="working">{{ 'NAV-BAR.LOGIN' | translate }} <i class="fas fa-sign-in-alt"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="control-group">
				<div class="row">
					<div class="col text-left">
						<a class="btn-link" (click)="doRegister()" data-dismiss="modal" aria-hidden="true" data-toggle="modal">{{ 'NAV-BAR.REGISTRAZIONE-ADV' | translate }}</a>
					</div>
					<div class="col text-right border-left">
						<a class="btn-link" (click)="doRecover()" data-dismiss="modal" aria-hidden="true" data-toggle="modal">{{ 'NAV-BAR.RECUPERO-PASSWORD' | translate }}</a>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
