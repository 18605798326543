<section id="scheda-tecnica" class="">
	<div class="attributes-box">
		<div *ngFor="let attributo of attributi"
			class="attribute"
			[ngClass]="getExtendedAttribute(attributo)">
			<div class="attribute-header">
				<div class="attribute-icon">
					<i class="fas" [ngClass]="getIconClass(attributo.tipoID)" title="{{attributo.nomeTipo}}"></i>
				</div>
				<div class="attribute-title">
					<div>{{attributo.nomeTipo}}</div>
				</div>
			</div>
			<div class="attribute-body">
				<div *ngFor="let v of attributo.valori">{{v}}</div>
			</div>
		</div>
	</div>
</section>
