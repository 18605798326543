<div class="container">
	<section class="intro-single">
		<div class="row">
			<div class="col">
				<div class="title-single-box">
					<h1 class="title-single">{{ ideaInfo?.nome }}</h1>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12 col-lg-9">
				<div id="gallery" *ngIf="slides.length > 0">
					<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
						<div ngxSlickItem *ngFor="let slide of slides" class="slide">
							<img src="{{ slide.img }}" alt="" width="100%">
						</div>
					</ngx-slick-carousel>
				</div>
			</div>
			<div class="col-md-12 col-lg-3">
				<nr-ideas-list></nr-ideas-list>
			</div>
		</div>
	</section>

	<section class="property-single nav-arrow-b">
		<div class="row">
			<div class="col-sm-12">
				<div class="row justify-content-between">
					<div class="col-md-6 section-md-t3">
						<div class="text-description" [innerHtml]="ideaInfo?.abstract"></div>
						<div class="row section-t3">
							<div class="col-sm-12">
								<div class="title-box-d">
									<h3 class="title-d">{{ 'PAGES.IDEAS.TITLE' | translate }}</h3>
								</div>
							</div>
						</div>
						<div class="amenities-list color-text-a">
							<ul class="list-a no-margin">
								<li>Balcony</li>
								<li>Outdoor Kitchen</li>
								<li>Cable Tv</li>
								<li>Deck</li>
								<li>Tennis Courts</li>
								<li>Internet</li>
								<li>Parking</li>
								<li>Sun Room</li>
								<li>Concrete Flooring</li>
							</ul>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div *ngFor="let tripCard of tripCards; let i = index" class="mb-3 col-sm-12" [attr.data-index]="i" [ngClass]="getTripCardGallerySizeClass(i)">
								<nr-trip-card [trip]="tripCard">
								</nr-trip-card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
