import {Injectable} from '@angular/core';
import {BackendService, GetWebsitesResponse, IApiRequest, UserService, WebsiteDef} from 'nr-common';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../../environments/environment';

type SITE_ACRONIMS = 'IT' | 'FR' | 'DE' | 'NL';

@Injectable()
export class WebsiteService {
	// Flag interni di sincronizzazione
	// public loadingMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public currentWebsite: WebsiteDef;
	public showAdvancedControls$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public readonly CMS_PAGELINK_ID = {
		home:         { IT: 26, FR: 49, DE: 0, NL: 0 }, // home
        destinations: { IT: 43, FR: 51, DE: 0, NL: 0 }, // destinations
        opendestpage: { IT: 44, FR:  0, DE: 0, NL: 0 }, // destinazioni aperte
		ideas:        { IT: 34, FR:  0, DE: 0, NL: 0 }, // ideas
		offers:       { IT: 35, FR:  0, DE: 0, NL: 0 }, // offers
		contacts:     { IT: 36, FR:  0, DE: 0, NL: 0 }, // contacts
		partners:     { IT: 37, FR:  0, DE: 0, NL: 0 }, // partners
		about:        { IT: 38, FR:  0, DE: 0, NL: 0 }, // about
	};

	public readonly CMS_FOOTERLINK_ID = {
		IT: 41,
		FR: 50,
        DE: 0,
        NL: 0
	};

	constructor(private user: UserService, private backend: BackendService) {
	}


	private async getWebsiteFromLang(): Promise<number> {
		let website: number;
		const locLang = await this.user.currentLang$.pipe(take(1)).toPromise();
		// console.log('locLang', locLang);
		switch (locLang) {
			case 'de':
                website = 4;
                break;
			case 'nl':
                website = 5;
                break;
			case 'fr':
				website = 2;
				break;
			default:
				website = 3;
				break;
		}
		return website;
	}

	public async init() {
		const request: IApiRequest = {};
		const response: GetWebsitesResponse = await this.backend.post(
			'websites/get',
			request
		);
		if (
			typeof environment.website === 'string' &&
			environment.website === 'auto'
		) {
			// Prod: riconoscimento in base alla lingua
			const websiteId = await this.getWebsiteFromLang();
			let website = response.websites.find((w) => w.id === websiteId);
			if (!website) {
				website = response.websites[0];
			}
			this.currentWebsite = website;
		} else {
			// Dev: id fissato
			this.currentWebsite = response.websites.find(
				(ws) => ws.id === +environment.website
			);
		}
		this.showAdvancedControls$.next(this.user.can(['intranet']));
	}

	formatPageTitle(pageName: string, siteName: string = null): string {
		// console.log("formatPageTitle", `${siteName} - ${pageName}`);
		return `${siteName} - ${pageName}`;
	}

	// Restituisce la chiave per il titolo della pagina in lingua
	getPageTitleKey(pageName: string): string {
		if (!pageName || pageName === '' || pageName.length === 0) {
			console.warn(`Invalid pageName: "${pageName}".`);
			return '';
		}
		pageName = this.getValidTranslationKey(pageName);
		return `PAGES.${pageName}.TITLE`;
	}

	getValidTranslationKey(keyName: string): string {
		return keyName.trim().toUpperCase().replace(' ', '-');
	}

	/**
	 * Traduce il nome di pagina delle rotte Angular in id numerico del CMS
	 * @param pageName nome della pagina usata come route di Angular
	 * @param siteLang Union Type degli acronimi delle lingue dei siti rappresentabili
	 * @returns id numerico della tabella PageLink (per il CMS)
	 */

	getPageLinkId(pageName: string, siteLang: SITE_ACRONIMS = null): number {
		if (siteLang == null) {
			siteLang = this.getUILanguage();
		}

		return this.CMS_PAGELINK_ID[pageName][siteLang];
	}

	/**
	 * Restituisce la lingua usata nella UI
	 * 	la lingua utente "vince" sulla lingua di default del sito
	 * @returns string della lingua in uso
	 */
	getUILanguage(): SITE_ACRONIMS {
		let lingua: string;
		let linguaUser: string;

		lingua = this.currentWebsite.defaultLangCode || 'IT';
		this.user.currentLang$.subscribe((o) => {
			linguaUser = o.toString();
		});

		return lingua.toUpperCase() as SITE_ACRONIMS;
	}

	/**
	 * Restituisce l'ID del footer in funzione della lingua (sito)
	 * @param siteLang Union Type degli acronimi delle lingue dei siti rappresentabili
	 * @returns id numerico della tabella PageLink afferente al footer (per il CMS)
	 */
	getFooterLinkId(siteLang: SITE_ACRONIMS = null): number {
		if (siteLang == null) {
			siteLang = this.getUILanguage();
		}

		return this.CMS_FOOTERLINK_ID[siteLang];
	}
}
