import { 
    AfterViewInit, 
    Component, 
    ContentChildren, 
    ElementRef, 
    EventEmitter, 
    Input, 
    NgZone, 
    OnInit, 
    Output, 
    QueryList, 
    SimpleChanges, 
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';

import { GoogleMapsAPIWrapper } from '../services/google-maps-api-wrapper';
import { FitBoundsService } from '../services/fit-bounds.service';
import { 
    InfoWindowManagerService, 
    MarkerManagerService, 
    PolylineManagerService, 
    PolygonManagerService, 
    CircleManagerService, 
    RectangleManagerService,
    AerialViewManagerService,
    //RouteManagerService,
} from '../services';
import { NrMapControl } from './nr-map-controls';
import { NrAerialView } from './nr-aerial-view';

@Component({
    selector: 'nr-maps',
    providers: [
        GoogleMapsAPIWrapper,
        MarkerManagerService,
        InfoWindowManagerService,
        PolylineManagerService,
        //RouteManagerService,
        FitBoundsService,
        PolygonManagerService,
        CircleManagerService,
        RectangleManagerService,
        AerialViewManagerService
        /* ,
        DataLayerManager,
        KmlLayerManager,
        LayerManager,
        , */
    ],
    template: `
        <div class="nr-map-container-inner" [style.display]="this.hasAerialView ? 'none' : undefined"></div>
        <div class="nr-map-content" [style.display]="this.hasAerialView ? 'inherit' : undefined">
            <ng-content></ng-content>
        </div>
        `,
    styles: [
    `
        .nr-map-container-inner {
            width: inherit;
            height: inherit;
        }
        .nr-map-content {
            display:none;
        }

        nr-mas-aerial-view{
            display: block;
            width: 100%;
        }
    `
    ],
})
export class NrMaps implements OnInit, AfterViewInit {
    /**
     * The longitude that defines the center of the map.
     */
    @Input() longitude = 0;
    
    /**
     * The latitude that defines the center of the map.
     */
    @Input() latitude = 0;
    
    /**
     * The zoom level of the map. The default zoom level is 8.
     */
    @Input() zoom = 8;

    /**
     * The minimal zoom level of the map allowed. When not provided, no restrictions to the zoom level
     * are enforced.
     */
    @Input() minZoom?: number;

    /**
     * The maximal zoom level of the map allowed. When not provided, no restrictions to the zoom level
     * are enforced.
     */
    @Input() maxZoom?: number;

    /**
     * The control size for the default map controls. Only governs the controls made by the Maps API itself
     */
    @Input() controlSize?: number;

    /**
     * Enables/disables if map is draggable.
     */
    // tslint:disable-next-line:no-input-rename
    @Input('mapDraggable') draggable = true;

    /**
     * Enables/disables zoom and center on double click. Enabled by default.
     */
    @Input() disableDoubleClickZoom = false;

    /**
     * Enables/disables all default UI of the Google map. Please note: When the map is created, this
     * value cannot get updated.
     */
    @Input() disableDefaultUI = false;

    /**
     * If false, disables scrollwheel zooming on the map. The scrollwheel is enabled by default.
     */
    @Input() scrollwheel = true;

    /**
     * Color used for the background of the Map div. This color will be visible when tiles have not
     * yet loaded as the user pans. This option can only be set when the map is initialized.
     */
    @Input() backgroundColor?: string;

    /**
     * The name or url of the cursor to display when mousing over a draggable map. This property uses
     * the css  * cursor attribute to change the icon. As with the css property, you must specify at
     * least one fallback cursor that is not a URL. For example:
     * [draggableCursor]="'url(http://www.example.com/icon.png), auto;'"
     */
    @Input() draggableCursor?: string;

    /**
     * The name or url of the cursor to display when the map is being dragged. This property uses the
     * css cursor attribute to change the icon. As with the css property, you must specify at least
     * one fallback cursor that is not a URL. For example:
     * [draggingCursor]="'url(http://www.example.com/icon.png), auto;'"
     */
    @Input() draggingCursor?: string;

    /**
     * If false, prevents the map from being controlled by the keyboard. Keyboard shortcuts are
     * enabled by default.
     */
    @Input() keyboardShortcuts = true;

    /**
     * Styles to apply to each of the default map types. Note that for Satellite/Hybrid and Terrain
     * modes, these styles will only apply to labels and geometry.
     */
    @Input() styles: google.maps.MapTypeStyle[] = [];

    /**
     * When true and the latitude and/or longitude values changes, the Google Maps panTo method is
     * used to
     * center the map. See: https://developers.google.com/maps/documentation/javascript/reference#Map
     */
    @Input() usePanning = false;

    /**
     * Sets the viewport to contain the given bounds.
     * If this option to `true`, the bounds get automatically computed from all elements that use the {@link NrFitBounds} directive.
     */
    @Input() fitBounds:
        | google.maps.LatLngBoundsLiteral
        | google.maps.LatLngBounds
        | boolean = false;

    /**
     * Padding amount for the bounds.
     */
    @Input() fitBoundsPadding?: number | google.maps.Padding;

    /**
     * The map mapTypeId. Defaults to 'roadmap'.
     */
    @Input() mapTypeId: keyof typeof google.maps.MapTypeId = 'ROADMAP';

    /**
     * When false, map icons are not clickable. A map icon represents a point of interest,
     * also known as a POI. By default map icons are clickable.
     */
    @Input() clickableIcons = true;

    /**
     * A map icon represents a point of interest, also known as a POI.
     * When map icons are clickable by default, an info window is displayed.
     * When this property is set to false, the info window will not be shown but the click event
     * will still fire
     */
    @Input() showDefaultInfoWindow = false;

    /**
     * This setting controls how gestures on the map are handled.
     * Allowed values:
     * - 'cooperative' (Two-finger touch gestures pan and zoom the map. One-finger touch gestures are not handled by the map.)
     * - 'greedy'      (All touch gestures pan or zoom the map.)
     * - 'none'        (The map cannot be panned or zoomed by user gestures.)
     * - 'auto'        [default] (Gesture handling is either cooperative or greedy, depending on whether the page is scrollable or not.
     */
    //@Input() gestureHandling: google.maps.GestureHandlingOptions = 'auto';

    /**
     * Controls the automatic switching behavior for the angle of incidence of
     * the map. The only allowed values are 0 and 45. The value 0 causes the map
     * to always use a 0° overhead view regardless of the zoom level and
     * viewport. The value 45 causes the tilt angle to automatically switch to
     * 45 whenever 45° imagery is available for the current zoom level and
     * viewport, and switch back to 0 whenever 45° imagery is not available
     * (this is the default behavior). 45° imagery is only available for
     * satellite and hybrid map types, within some locations, and at some zoom
     * levels. Note: getTilt returns the current tilt angle, not the value
     * specified by this option. Because getTilt and this option refer to
     * different things, do not bind() the tilt property; doing so may yield
     * unpredictable effects. Enable it with value 45.)
     */
    @Input() tilt = 0;
    @Input() heading = 0;

    /**
     * Used for set the map style choosen from the Google maps platform cloud
     */
    @Input() mapId: string | null = null;
    
    /**
     * Options for restricting the bounds of the map.
     * User cannot pan or zoom away from restricted area.
     */
    @Input() restriction?: google.maps.MapRestriction;


    @Input() overlayView? = false;

    @Input() autoCenterMap = false;
    @Input() autoCenterMapTimeout = 2000;
    
    @ContentChildren(NrAerialView) private aerialView: NrAerialView;
    public get hasAerialView(): boolean {
        return this.aerialView != null && this._aerialManagerService.hasAerialViews;
    }

    /**
     * Map option attributes that can change over time
     */
    private static _mapOptionsAttributes: string[] = [
        'disableDoubleClickZoom',
        'scrollwheel',
        'draggable',
        'draggableCursor',
        'draggingCursor',
        'keyboardShortcuts',
        'styles',
        'zoom',
        'minZoom',
        'maxZoom',
        'mapTypeId',
        'clickableIcons',
        'gestureHandling',
        'tilt',
        'restriction',
    ];

    private _observableSubscriptions: Subscription[] = [];
    private _fitBoundsSubscription: Subscription;

    /**
     * This event emitter gets emitted when the user clicks on the map (but not when they click on a
     * marker or infoWindow).
     */
    // tslint:disable-next-line: max-line-length
    @Output() mapClick: EventEmitter<google.maps.MapMouseEvent | google.maps.IconMouseEvent> = new EventEmitter<google.maps.MapMouseEvent | google.maps.IconMouseEvent>();

    /**
     * This event emitter gets emitted when the user right-clicks on the map (but not when they click
     * on a marker or infoWindow).
     */
    @Output() mapRightClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event emitter gets emitted when the user double-clicks on the map (but not when they click
     * on a marker or infoWindow).
     */
    @Output() mapDblClick: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event emitter is fired when the map center changes.
     */
    @Output() centerChange: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter<google.maps.LatLngLiteral>();

    /**
     * This event is fired when the viewport bounds have changed.
     */
    @Output() boundsChange: EventEmitter<google.maps.LatLngBounds> = new EventEmitter<google.maps.LatLngBounds>();

    /**
     * This event is fired when the mapTypeId property changes.
     */
    @Output() mapTypeIdChange: EventEmitter<google.maps.MapTypeId> = new EventEmitter<google.maps.MapTypeId>();

    /**
     * This event is fired when the map becomes idle after panning or zooming.
     */
    @Output() idle: EventEmitter<void> = new EventEmitter<void>();

    /**
     * This event is fired when the zoom level has changed.
     */
    @Output() zoomChange: EventEmitter<number> = new EventEmitter<number>();

    /**
     * This event is fired when the google map is fully initialized.
     * You get the google.maps.Map instance as a result of this EventEmitter.
     */
    @Output() mapReady: EventEmitter<google.maps.Map> = new EventEmitter<google.maps.Map>();

    /**
     * This event is fired when the visible tiles have finished loading.
     */
    @Output() tilesLoaded: EventEmitter<void> = new EventEmitter<void>();

    @ContentChildren(NrMapControl) mapControls: QueryList<NrMapControl>;

    constructor(
        private _zone: NgZone,
        private _elem: ElementRef,
        private _mapsWrapper: GoogleMapsAPIWrapper,
        protected _fitBoundsService: FitBoundsService,
        private _aerialManagerService: AerialViewManagerService
    ) { }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        const container = this._elem.nativeElement.querySelector(
            '.nr-map-container-inner'
        );
        this._initMapInstance(container);
    }

    private _initMapInstance(el: HTMLElement) {

        const center = { lat: this.latitude || 0, lng: this.longitude || 0};

        const mapOptions: google.maps.MapOptions = {
            mapId: this.mapId,
            center: center,
            zoom: this.zoom,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            controlSize: this.controlSize,
            disableDefaultUI: this.disableDefaultUI,
            disableDoubleClickZoom: this.disableDoubleClickZoom,
            scrollwheel: this.scrollwheel,
            backgroundColor: this.backgroundColor,
            draggable: this.draggable,
            draggableCursor: this.draggableCursor,
            draggingCursor: this.draggingCursor,
            keyboardShortcuts: this.keyboardShortcuts,
            mapTypeId: this.mapTypeId.toLocaleLowerCase(),
            clickableIcons: this.clickableIcons,
            tilt: this.tilt,
            heading: this.heading,
            restriction: this.restriction,
        };
        if (this.mapId == null) {
            mapOptions.styles = this.styles;
        }

        this._mapsWrapper
            .createMap(el, mapOptions)
            .then(() => this._mapsWrapper.getNativeMap())
            .then((map) => {
                this.mapReady.emit(map);
            });

        // Auto-center map management
        this.originalCenter = center;

        // register event listeners
        this._handleMapCenterChange();
        this._handleMapZoomChange();
        this._handleMapMouseEvents();
        this._handleBoundsChange();
        this._handleMapTypeIdChange();
        this._handleTilesLoadedEvent();
        this._handleIdleEvent();
        this._handleControlChange();  
    }

    private _handleMapTypeIdChange() {
        const s = this._mapsWrapper.subscribeToMapEvent('maptypeid_changed').subscribe(() => {
            this._mapsWrapper.getMapTypeId().then(
                (mapTypeId: google.maps.MapTypeId) => { this.mapTypeIdChange.emit(mapTypeId); });
        });
        this._observableSubscriptions.push(s);
    }

    private _handleMapCenterChange() {
        const s = this._mapsWrapper.subscribeToMapEvent('center_changed').subscribe(() => {
            this._mapsWrapper.getCenter().then((center: google.maps.LatLng) => {
                this.latitude = center.lat();
                this.longitude = center.lng();
                this.centerChange.emit({ lat: this.latitude, lng: this.longitude } as google.maps.LatLngLiteral);

                this._autoCenterMap();
            });
        });
        this._observableSubscriptions.push(s);
    }

    private _handleBoundsChange() {
        const s = this._mapsWrapper.subscribeToMapEvent('bounds_changed').subscribe(() => {
            this._mapsWrapper.getBounds().then(
                (bounds: google.maps.LatLngBounds) => { this.boundsChange.emit(bounds); });
        });
        this._observableSubscriptions.push(s);
    }

    private _handleMapZoomChange() {
        const s = this._mapsWrapper.subscribeToMapEvent('zoom_changed').subscribe(() => {
            this._mapsWrapper.getZoom().then((z: number) => {
                this.zoom = z;
                this.zoomChange.emit(z);
            });
        });
        this._observableSubscriptions.push(s);
    }

    private _handleMapMouseEvents() {

        const click = this._mapsWrapper.subscribeToMapEvent('click').subscribe((event) => {
            // the placeId will be undefined in case the event was not an IconMouseEvent (google types)
            if ((event as google.maps.IconMouseEvent).placeId && !this.showDefaultInfoWindow) {
                event.stop();
            }
            this.mapClick.emit(event);
        });
        this._observableSubscriptions.push(click);

        const dblClick = this._mapsWrapper.subscribeToMapEvent('dblclick').subscribe((event) => {
            // the placeId will be undefined in case the event was not an IconMouseEvent (google types)
            if ((event as google.maps.IconMouseEvent).placeId && !this.showDefaultInfoWindow) {
                event.stop();
            }
            this.mapDblClick.emit(event);
        });
        this._observableSubscriptions.push(dblClick);

        const rightClick = this._mapsWrapper.subscribeToMapEvent('rightclick').subscribe((event) => {
            // the placeId will be undefined in case the event was not an IconMouseEvent (google types)
            if ((event as google.maps.IconMouseEvent).placeId && !this.showDefaultInfoWindow) {
                event.stop();
            }
            this.mapRightClick.emit(event);
        });
        this._observableSubscriptions.push(rightClick);
    }

    private _handleTilesLoadedEvent() {
        const s = this._mapsWrapper.subscribeToMapEvent('tilesloaded').subscribe(
            () => this.tilesLoaded.emit(void 0),
        );
        this._observableSubscriptions.push(s);
    }

    private _handleIdleEvent() {
        const s = this._mapsWrapper.subscribeToMapEvent('idle').subscribe(
            () => { this.idle.emit(void 0); });
        this._observableSubscriptions.push(s);
    }

    private _handleControlChange() {
        this._setControls();
        this.mapControls.changes.subscribe(() => this._setControls());
    }

    private _setControls() {
        const controlOptions: Partial<google.maps.MapOptions> = {
            fullscreenControl: !this.disableDefaultUI,
            mapTypeControl: false,
            panControl: false,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: !this.disableDefaultUI,
            zoomControl: !this.disableDefaultUI,
        };
        this.mapControls.forEach(control => Object.assign(controlOptions, control.getOptions()));
        this._mapsWrapper.setMapOptions(controlOptions);
    }

    /* @internal */
    public ngOnChanges(changes: SimpleChanges) {
        this._updateMapOptionsChanges(changes);
        this._updatePosition(changes);
    }
    
    private _updateMapOptionsChanges(changes: SimpleChanges) {
        const options: { [propName: string]: any } = {};
        const optionKeys =
            Object.keys(changes).filter(k => NrMaps._mapOptionsAttributes.indexOf(k) !== -1);
        optionKeys.forEach((k) => { options[k] = changes[k].currentValue; });
        this._mapsWrapper.setMapOptions(options);
    }

    /**
     * Triggers a resize event on the google map instance.
     * When recenter is true, the of the google map gets called with the current lat/lng values or fitBounds value to recenter the map.
     * Returns a promise that gets resolved after the event was triggered.
     */
    triggerResize(recenter: boolean = true): Promise<void> {
        // Note: When we would trigger the resize event and show the map in the same turn (which is a
        // common case for triggering a resize event), then the resize event would not
        // work (to show the map), so we trigger the event in a timeout.
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                return this._mapsWrapper.triggerMapEvent('resize').then(() => {
                    if (recenter) {
                        this.fitBounds != null ? this._fitBounds() : this._setCenter();
                    }
                    resolve();
                });
            });
        });
    }

    private _updatePosition(changes: SimpleChanges) {
        // tslint:disable: no-string-literal
        if (changes['latitude'] == null && changes['longitude'] == null &&
            !changes['fitBounds']) {
            // no position update needed
            return;
        }
        // tslint:enable: no-string-literal

        // we prefer fitBounds in changes
        if ('fitBounds' in changes) {
            this._fitBounds();
            return;
        }

        if (typeof this.latitude !== 'number' || typeof this.longitude !== 'number') {
            return;
        }
        this._setCenter();
    }

    private _setCenter() {
        const newCenter = {
            lat: this.latitude,
            lng: this.longitude,
        };
        if (this.usePanning) {
            this._mapsWrapper.panTo(newCenter);
        } else {
            this._mapsWrapper.setCenter(newCenter);
        }
    }

    private _fitBounds() {
        switch (this.fitBounds) {
            case true:
                this._subscribeToFitBoundsUpdates();
                break;
            case false:
                if (this._fitBoundsSubscription) {
                    this._fitBoundsSubscription.unsubscribe();
                }
                break;
            default:
                if (this._fitBoundsSubscription) {
                    this._fitBoundsSubscription.unsubscribe();
                }
                this._updateBounds(this.fitBounds, this.fitBoundsPadding);
        }
    }

    private _subscribeToFitBoundsUpdates() {
        this._zone.runOutsideAngular(() => {
            this._fitBoundsSubscription = this._fitBoundsService.getBounds$().subscribe(b => {
                this._zone.run(() => this._updateBounds(b, this.fitBoundsPadding));
            });
        });
    }

    protected _updateBounds(bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral, padding?: number | google.maps.Padding) {
        if (!bounds) {
            return;
        }
        if (this._isLatLngBoundsLiteral(bounds) && typeof google !== 'undefined' && google && google.maps && google.maps.LatLngBounds) {
            const newBounds = new google.maps.LatLngBounds();
            newBounds.union(bounds);
            bounds = newBounds;
        }
        if (this.usePanning) {
            this._mapsWrapper.panToBounds(bounds, padding);
            return;
        }
        this._mapsWrapper.fitBounds(bounds, padding);
    }

    private _isLatLngBoundsLiteral(bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral): boolean {
        return bounds != null && (bounds as any).extend === undefined;
    }

    /** @internal */
    public ngOnDestroy() {
        // unsubscribe all registered observable subscriptions
        this._observableSubscriptions.forEach((s) => s.unsubscribe());

        // remove all listeners from the map instance
        this._mapsWrapper.clearInstanceListeners();
        if (this._fitBoundsSubscription) {
            this._fitBoundsSubscription.unsubscribe();
        }
    }

    private originalCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
    private moveCenterTimer: any;
    private _autoCenterMap() {
        if (!this.autoCenterMap) return;

        if (this.moveCenterTimer != null) {
            clearTimeout(this.moveCenterTimer);
        }
        this.moveCenterTimer = setTimeout(() => {
            if (this.usePanning) {
                this._mapsWrapper.panTo(this.originalCenter);
            } else {
                this._mapsWrapper.setCenter(this.originalCenter);
            }
        }, this.autoCenterMapTimeout);       
    }




}
