import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { BROWSER_GLOBALS_PROVIDERS } from './utils/browser-globals';
import { MAPS_API_CONFIG, MapsAPILoader, MapsAPILoaderConfig } from './utils/maps-api-loader';

import { NrMaps } from './directives/nr-maps';
import { NrMarker } from './directives/nr-marker';
import { NrInfoWindow } from './directives/nr-info-window';
import { 
    NrAerialView, 
    NrCircle, 
    NrFitBounds, 
    NrPolygon, 
    NrPolyline, 
    NrPolylineIcon, 
    NrPolylinePoint, 
    NrRectangle,
    NrFullscreenControl, 
    NrMapTypeControl, 
    NrPanControl, 
    NrRotateControl, 
    NrScaleControl, 
    NrStreetViewControl, 
    NrZoomControl,
} from './directives';
import { CommonModule } from '@angular/common';
import { NrMapsRoute } from './directives/nr-route';

const CORE = [
    NrMaps,
    NrMarker,
    NrInfoWindow,
    NrPolyline,
    NrPolylinePoint,
    NrPolylineIcon,
    NrPolygon,
    NrCircle,
    NrRectangle,
    NrMapsRoute,
    NrFitBounds,
    NrAerialView,
    NrFullscreenControl,
    NrMapTypeControl,
    NrPanControl,
    NrRotateControl,
    NrScaleControl,
    NrStreetViewControl,
    NrZoomControl
]

@NgModule({
    declarations: [
        ...CORE
    ],
    imports: [
        HttpClientModule,
        CommonModule
    ],
    exports: [
        ...CORE
    ],
})
export class NrMapsModule {
    /**
     * Please use this method when you register the module at the root level.
     */
    static forRoot(mapsAPILoaderConfig?: MapsAPILoaderConfig): ModuleWithProviders<NrMapsModule> {
        return {
            ngModule: NrMapsModule,
            providers: [
                ...BROWSER_GLOBALS_PROVIDERS,
                { provide: MapsAPILoader, useClass: MapsAPILoader },
                { provide: MAPS_API_CONFIG, useValue: mapsAPILoaderConfig }
            ],
        };
    }
}
