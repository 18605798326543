import {
	Component,
	OnInit
} from '@angular/core';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { result } from 'lodash';
import {
	MenuData,
	PageData,
	PageDataGeneric
} from 'nr-common';
import { environment } from 'projects/nr-b2c/src/environments/environment';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';

@Component({
	selector: "nr-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	public menuVoices: MenuData[];
	public footerData: PageData = null;
	public footerLinkId: number = -1;

	public readonly COLUMN_GENERAL: number = 1;
	public readonly COLUMN_INFO: number = 2;

	constructor(
		private apiService: ApiService,
		public webSiteService: WebsiteService
	) {}

	ngOnInit(): void {
		this.apiService.getMenu().subscribe((data: MenuData[]) => {
			this.menuVoices = data;
		});

		if (this.footerData == null) {
			this.footerLinkId = this.webSiteService.getFooterLinkId();

			this.apiService
				.getPageFooter(this.footerLinkId)
				.subscribe((data: PageData) => {
					this.footerData = data;
				});
		}
	}

	getArticleLink(id: number): string {
		return `/article/${id}`;
	}

	getArticleName(name: string): string {
		return name;
		// Spengo la funzione che metteva in maiuscolo SOLO la prima lettera.
		// Lasciando TOTALMENTE in balia della stesura dell'articolo il CASE dello stesso
		// name = '' + name;
		// return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
	}

	getFooterColumn(columIndex: number): PageDataGeneric[] {
		if (this.footerData == null) return [];

		let result: PageDataGeneric[] = [];
		if (this.footerData.elencoDati) {
			result = this.footerData.elencoDati.filter((e) => {
				if (e.group === columIndex) {
					return e;
				}
			});
		}
		return result;
	}

	isPrivacyLink(item: PageDataGeneric): boolean {
		return item.entityId == environment.DINAMIC_COOKIES_ARTICLE_ID;
	}

	/* openPrivacyLink(item: PageDataGeneric) {
		const url: string = `/article/${item.entityId}`;
		window.open(url, "_blank");
	} */
}
