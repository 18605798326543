
export enum AerialViewStateEnum {
    Failed = "FAILED",
    Active = "ACTIVE",
    Processing = "PROCESSING",
    Unspecified = "STATE_UNSPECIFIED"
}

export enum AerialMetadataFormatEnum {
    Image = "IMAGE",
    Mp4High = "MP4_HIGH",
    Mp4Low = "MP4_LOW",
    Mp4Medium = "MP4_MEDIUM",
    Dash = "DASH",
    Hls = "HLS",
}

export type AerialViewUri = {
    landscapeUri: string,
    portraitUri: string
}

export class AerialViewMetadata {
    public videoId: string;
    public capturedDate: { year: number, month: number, day: number };
    public duration: string;
}

export class AerialViewGetVideoResponse {
    public state: AerialViewStateEnum;
    public metadata: AerialViewMetadata;
    public uris: Map<string, AerialViewUri>;
}