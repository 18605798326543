import { Injectable } from '@angular/core';

import { UserService } from 'nr-common';

import { environment } from '../../environments/environment';

// OM 08.06.2023: Google Analytics
@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private _ready : boolean = false;
    public get ready() : boolean {
        return this._ready;
    }
    private set ready(v : boolean) {
        this._ready = v;
    }

    private userService: UserService;

    constructor() { 
        this.onScriptReady = this.onScriptReady.bind(this);
    }

    public init(userService: UserService) {
        try {
            this.userService = userService;

            // Add custom element of script with following attributes
            let script: HTMLScriptElement = document.createElement("script");
            script.addEventListener("load", this.onScriptReady);
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsMeasurementId;
            document.head.prepend(script);
            
            if (!environment.production) {
                console.log("GoogleAnalyticsService - init", environment.googleAnalyticsMeasurementId);
            }

        } catch (err) {
            console.error("GoogleAnalyticsService - Error initializing Google Tag Manager", err);
        }
    }

    public tag(pageTitle: string, pageUrl: string, pageLocation: string) {
        try {
            const tagData = {
                page_title: pageTitle,
                page_path: pageUrl,
                page_location: pageLocation,
                user_id: this.userService.isLoggedIn ? this.userService.currentUser.id : undefined,
                debug_mode: !environment.production ? "true" : undefined
            };
            
            // We are using a different stream for production but is better to not send data to google if not in production
            if(!environment.production) {
                console.log("GoogleAnalyticsService - tag", tagData);
            }
            
            gtag('event', 'page_view', tagData);

        } catch (err) {
            console.error("GoogleAnalyticsService - Error sending tag", err);
        }
    }

    private onScriptReady(event: any) {

        if (!environment.production) {
            console.log("GoogleAnalyticsService - onScriptReady");
        }

        gtag('js', new Date());
        gtag('config', environment.googleAnalyticsMeasurementId, { send_page_view: false });

        this.ready = true;
    }
}
