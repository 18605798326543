import { Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UserService } from 'nr-common';

import { environment } from '../environments/environment';
import { WebsiteService } from './services/website.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    #s1: Subscription;

    constructor(
        translate: TranslateService,
        website: WebsiteService,
        user: UserService,
        private google: GoogleAnalyticsService
    ) {

        // OM 08.06.2023: Google Analytics
        this.google.init(user);

        translate.addLangs(['it', 'en', 'fr', 'es', 'de', 'nl']);
        // translate.setDefaultLang('de');
        // translate.setDefaultLang('es');
        // translate.setDefaultLang('en');
        // translate.setDefaultLang('fr');
        translate.setDefaultLang('it');

        // Così si imposta prima che vengano caricati i contenuti
        this.#s1 = user
            .currentLang$
            .subscribe(lang => {
                translate.use(lang);
            })

        // translate.use("fr");
    }
    
    ngOnDestroy(): void {
        this.#s1?.unsubscribe();
    }
}
