import {
	Component,
	OnInit
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nr-partnerspage',
  templateUrl: './partnerspage.component.html',
  styleUrls: ['./partnerspage.component.scss']
})
export class PartnerspageComponent extends BasePageComponent implements OnInit {

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'partners';
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
