import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { OpenDestination, OpenDestinationArticle } from 'nr-common';
import { CommonService } from '../../services/common.service';
import { UrlService } from '../../services/url.service';

const MAX_DESCR_LEN = 200;

@Component({
	selector: 'nr-opendest-card',
	templateUrl: './opendest-card.component.html',
	styleUrls: ['./opendest-card.component.scss']
})
export class OpendestCardComponent implements OnInit {
	get invertedColor(): string {
		return this.commonService.invertColor(this.openDestination.destinazione.gaBgColor, this.commonService.INVERT_ONLY_BLACK_AND_WHITE);
	}
	@Input() openDestination: OpenDestination;

	constructor(
		public urlService: UrlService,
		public commonService: CommonService
	) { }

	ngOnInit(): void {
	}

	getIcon(iconGliph: string): string {
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}

	getColor(bgColor: string): string {
		if(!bgColor) return '#999';
		if (!bgColor.startsWith("#")) {
			bgColor = "#" + bgColor;
		}
		return bgColor;
	}

	transformName(name: string): string {
		return this.commonService.capitalizeAllWords(name);
	}

	endSoftly(v: string): string {
		if (v && v.length > MAX_DESCR_LEN) {
			v = v.substr(0, MAX_DESCR_LEN);
			const lastBlank = v.lastIndexOf(' ');
			v = v.substr(0, lastBlank) + '...';
		}
		return v;
	}

	getArticleUrl(articleId: number): string {
		return this.urlService.getArticleUrl(articleId);
	}

	getArticlesOrderByTitle(articles: OpenDestinationArticle[]): OpenDestinationArticle[] {
		return articles.sort((a, b) => {
			return (a.title > b.title) ? 1 : -1;
		})
	}

	navigateToOpenDestination(id: number) {
		return this.urlService.navigateToOpenDestination(id, this.openDestination.destinazione.luogoID);
	}

	getArticleTitle(title: string) {
		return this.commonService.capitalizeAllWords(title, 3);
	}

	getCoverImage(imageUrl: string): string {
		if (!imageUrl || imageUrl === undefined || imageUrl.length == 0)
			return this.commonService.getVoidImage();
		return imageUrl;
	}
}
