<section class="intro-single"></section>
<section data-rem="article">
	<div class="container">
		<nr-opendest-card *ngIf="openDestination" class="force-right"
			[openDestination]="openDestination"></nr-opendest-card>
			<ng-container *ngIf="article">
				<article *ngIf="article.title"><h1>{{ article.title }}</h1></article>
				<article class="article-body" [innerHtml]="article.text"></article>
			</ng-container>
	</div>
</section>
