const allowedLanguages = ['it', 'en', 'fr', 'de', 'es', 'pt', 'nl', 'zu'];
export type Languages = 'it' | 'en' | 'fr' | 'de' | 'es' | 'pt' | 'nl' | 'zu';
export type IntranetThemes = 'default' | 'corporate' | 'cosmic';
export type Themes = '' | 'marco' | 'dark';

export class UiUserOptions {
	public static readonly allowedLanguages = allowedLanguages;

	/** Codice ISO 2 lettere della lingua dell'interfaccia */
	public lang: Languages = 'it';

	/** Nome del tema della intranet */
	public intranetTheme: IntranetThemes = 'corporate';

	/** Nome del tema del B2B */
	public theme: Themes = '';

	/** Se true la privacy è stata visionata */
	public presaVisionePrivacy: string = 'false';

	// public get presaVisionePrivacy(): boolean {
	// 	return this._presaVisionePrivacy;
	// }

	// /**
	// 	Forzo l'impostazione della property a boolean
	// 	per essere considerata valida devo avere un boolean
	// 	oppure una stringa che in uppercase TRUE
	// 	Questa forzature è dovuta alla deserializzazione del valore da JSON
	// 	che viene restituita come stringa */
	// public set presaVisionePrivacy(v: string | boolean) {
	// 	let _value: boolean = false;
	// 	_value = (typeof v === 'string') ? v.toUpperCase() === 'TRUE' : v;
	// 	this._presaVisionePrivacy = _value;
	// }

	constructor(data?: any) {
		this.lang = data?.lang ?? 'it';
		this.intranetTheme = data?.intranetTheme || 'corporate';
		this.theme = data?.theme || '';
		this.presaVisionePrivacy = data?.presaVisionePrivacy || false;

		// controllo il valore dai dati
		// faccio un controllo in UpperCase() con TRUE, almeno ho un boolean sensato.
		// Infatti possono capitare valori stringa come True, true, False, false.
		// const stringFlag = data?.presaVisionePrivacy ?? 'false';
		// this.presaVisionePrivacy = stringFlag.toUpperCase() === 'TRUE';
	}
}

export class AppUserOptions {
	public fuorisede: boolean;
	public emailFuorisede: string;
}

export class LocalUserOptionsItem {
	public id: number;
	public options: UiUserOptions;
}

export class LocalUserOptionsStorage {
	public userOptions: LocalUserOptionsItem[];
}
