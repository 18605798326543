export const environment = {
	production: true,
	i18nFiles: 'websitelabels/i18n/',
    servicesEndpointRoot: 'https://api.naar.com/',
	website: 'auto',	//3,
	googleMapsApiKey: 'AIzaSyBSNG0UvbqrSdk0JPejA3_K-eDXTCbpRv4',
    googleAnalyticsMeasurementId: 'G-Z0XGRWD1H3',
	b2bIntranetUrl: 'https://intranet.naar.com/in/dashboard',

	b2bIntranetPwdRecover: 'https://itb2b.naar.com/auth/recovery',
	b2bIntranetUsrSignIn: 'https://itb2b.naar.com/auth/recovery',
    b2bIntranetAdvUrl: {
        it: 'https://itb2b.naar.com/agency/dashboard',
        fr: 'https://frb2b.naar.com/agency/dashboard',
        de: 'https://deb2b.naar.com/agency/dashboard',
        nl: 'https://beb2b.naar.com/agency/dashboard',
    },
    b2bIntranetAdvTripList: {
        it: 'https://itb2b.naar.com/agency/orders/advanced',
        fr: 'https://frb2b.naar.com/agency/orders/advanced',
        de: 'https://deb2b.naar.com/agency/orders/advanced',
        nl: 'https://beb2b.naar.com/agency/orders/advanced'
    },
	b2cBaseAttachmentUrl: 'https://it.naar.com/download/',

	DINAMIC_COOKIES_ARTICLE_ID: 182068
};
