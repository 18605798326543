export class Slide {
	id: number;
	img: string;
	name: string;
	caption: string;

	constructor(img: string, name: string = '', caption: string = '', id: number = 0) {
		this.id = id;
		this.img = img;
		this.name = name;
		this.caption = caption;
	}
}
