import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/nr-b2c/src/environments/environment';
import { ApiService } from '../../services/api.service';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
	selector: 'nr-articlepage',
	templateUrl: './articlepage.component.html',
	styleUrls: ['./articlepage.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ArticlepageComponent implements OnInit {

    public articleID: number = 0;
	public article: string; // B2cArticle;
	public extraArticle: string = null;
	private dinamicCookieScriptID: string = 'CookieDeclaration';
    private dinamicCookieScriptURL: string = 'https://consent.cookiebot.com/ea688d73-4a8e-4f35-9770-0f179e512a5d/cd.js';

    constructor(
		private apiService: ApiService,
		private activeRoute: ActivatedRoute,
		public translateService: TranslateService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
        private el: ElementRef,
        private title: Title,
        private google: GoogleAnalyticsService,
        private router: Router
	) {}

	ngOnInit(): void {
		this.activeRoute.params.subscribe((routeParams) => {
			this.apiService.getArticle(routeParams.id, true).subscribe((data) => {

                // OM 09.06.2023: setting the title of the page
                this.title.setTitle(data.title ? "Naar Tour Operator - " + data.title : "Naar Tour Operator");
				
                this.article = data.text;
                
				if (this.HasDynamicCookies(routeParams.id)) {
					this.extraArticle = 'AGGIUNGO LO SCRIPT';
					this.injectScript();
				} else {
					this.extraArticle = null;
					this.removeScript();
				}

                // OM 08.06.2023: Google Analytics
                this.google.tag(data.title,
                                this.router.url,
                                this.document.location.href);
			});
		});
	}

	private HasDynamicCookies(articleId: number): boolean {
		const result: boolean = articleId == environment.DINAMIC_COOKIES_ARTICLE_ID;
		return result;
	}

	// Inserisce nel DOM della pagina lo script per i cookie dinamici
	private injectScript() {
		const dynamicCookiesScripNode = this.renderer.createElement('script');
		dynamicCookiesScripNode.type = 'text/javascript';
		dynamicCookiesScripNode.id = this.dinamicCookieScriptID;
		dynamicCookiesScripNode.src = this.dinamicCookieScriptURL;
		dynamicCookiesScripNode.text = '';
        // OM 09.06.2023: the script was placed after the footer
        this.renderer.appendChild(this.el.nativeElement, dynamicCookiesScripNode);
	}

	// Rimuove dal DOM della pagina lo script per i cookie dinamici
	public removeScript() {
		const dynamicCookiesScripNode = this.document.getElementById(this.dinamicCookieScriptID);
		if (!dynamicCookiesScripNode) return;
		const scriptNode = this.renderer.removeChild(this.document.body, dynamicCookiesScripNode);
	}
}
