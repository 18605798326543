import {
	Component,
	OnInit
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'nr-aboutpage',
	templateUrl: './aboutpage.component.html',
	styleUrls: ['./aboutpage.component.scss']
})
export class AboutpageComponent extends BasePageComponent implements OnInit {

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'about';
		// this.pageLinkId = this.webSiteService.CMS_PAGELINK_ID['about'];	// 37 è il CMS-Id della pagina Idee del NAAR-B2c
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
