import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {
	Aeroporto,
	ArticoloTestataRow,
	Banca,
	CommonLabels,
	CompagniaAerea,
	ContactCategory,
	DatoAggiuntivoTipoRow,
	AdditionalDataTypeRow,
	DocumentType,
	ELingua,
	ETipoDocumento,
	EVisibilitaTeam,
	Fornitore,
	GetAllSectionsResponse,
	GetAllTemplatesResponse,
	GetArticleHeadsResponse,
	GetContactCategoriesResponse,
	GetDatiAggiuntiviServiziResponse,
	GetDocumentTypesResponse,
	GetExtraDocumentsResponse,
	GetMiniguideResponse,
	GetNearbyPlacesResponse,
	GetOperatorsResponse,
	GetOrdersTagsResponse,
	GetPackageRulesResponse,
	GetPermissionsResponse,
	GetPhraseTagsResponse,
	GetPoliciesListResponse,
	GetRolesResponse,
	GetServicesOffersTypesResponse,
	GetTeamsResponse,
	GetWebsitesResponse,
	IApiRequest,
	IGetNearbyPlacesRequest,
	IGetPublishedServiceRequest,
	IGetPublishedServiceResponse,
	IGetTemplatesRequest,
	ILocalizedRequest,
	IMiniguideRequest,
	ImmaginePubblicitaria,
	IOrderTagData,
	ISiteRequest,
	IStatisticsAllRequest,
	IStatisticsAllResponse,
	ITextsGetFrasiDefaultRequest,
	ITextsGetItinerarioRequest,
	LuogoTop,
	LuogoVicino,
	ModalitaPagamento,
	OperatorData,
	PenaleRow,
	RegolaPacchettoRow,
	RoleData,
	SectionsRow,
	ServiceData,
	ServiceOfferTypeRow,
	SistemazioneListRow,
	Site,
	StatisticsRow,
	StatoVolo,
	SystemEmailRow,
	TagView,
	TeamData,
	TemplatesRow,
	TextsGetFrasiDefaultResponse,
	TextsGetItinerarioResponse,
	TranslateArticleSection,
	TrattamentoTradotto,
	ValutaCorrente,
	IGetFlightsTicketsRequest,
	IGetFlightsTicketsResponse,
	VoloTickets,
	IServizioGetRequest,
	IGetTipologieResponse,
	TipologiaListRow,
	AdditionalsDataTypesGetResponse,
	IGetTipologieRequest,
    EOperatore,
} from '../backend/naar-api-client';
import {BackendService} from '../backend/backend.service';

//import { AdditionalDataComponent } from 'projects/nr-intranet/src/app/pages/intranet/programmazione/additional-data/additional-data.component';

/**
 * Opzioni UI dei tag
 */
export interface TagUiOptions {
	/**
	 * Colore del testo, formato accettato dal css
	 */
	color?: string,

	/**
	 * Colore dello sfondo
	 */
	backgroundColor?: string,

	/**
	 * Classe CSS dell'icona
	 */
	iconClass?: string
}

/**
 * Tag offerte con i suoi dati esposti
 */
export type UiOrdersTagData = IOrderTagData & TagUiOptions;

export interface OrderTagsDictionary {
	[id: number]: UiOrdersTagData
}

@Injectable()
export class BaseDataService {
	public readonly LUOGO_SERVIZI_ACCESSORI_ID = 5;
	public readonly LUOGO_IN_VOLO_ID = 3909;
	public readonly SERVIZIO_MANUALE_ID = 30345;
	public readonly TIPOLOGIA_NON_MAPPATA_ID = 80038;

	public readonly GIORNO_DEFAULT_INIZIO_TAPPA = 0;
	public readonly GIORNO_DEFAULT_FINE_TAPPA = 255;

	public readonly OFFERTA_COMPONIBILE_ID = 7;

	private _currencies$: Observable<ValutaCorrente[]> = null;
	private _contractPayments$: Observable<ModalitaPagamento[]> = null;
	private _banks$: Observable<Banca[]> = null;
	private _fornitori$: Observable<Fornitore[]> = null;
	private _systemEmails$: Observable<SystemEmailRow[]> = null;
	private _beddings$: Observable<SistemazioneListRow[]> = null;
	private _companies$: Observable<CompagniaAerea[]> = null;
	private _airports$: Observable<Aeroporto[]> = null;
	private _flightStatues$: Observable<StatoVolo[]> = null;
	private _mealPlans$: Observable<TrattamentoTradotto[]> = null;
	private _advertiseImages$: Observable<ImmaginePubblicitaria[]> = null;
	private _topLocations$: Observable<LuogoTop[]> = null;
	private _articleHeads$: Observable<ArticoloTestataRow[]> = null;
	private _sites$: Observable<Site[]> = null;
	private _packageRules$: Observable<RegolaPacchettoRow[]> = null;
	private _policies$: Observable<PenaleRow[]> = null;
	private _servicesOffersTypes$: Observable<ServiceOfferTypeRow[]> = null;
	private _serviceAdditionalData$: Observable<DatoAggiuntivoTipoRow[]> = null;
	private _serviceAdditionalDataType: Observable<AdditionalDataTypeRow[]> = null;
	private _extraDocs$: Observable<GetExtraDocumentsResponse> = null;
	private _phraseTags$: Observable<TagView[]> = null;
	private _articlesSections$: Observable<SectionsRow[]> = null;
	private _allTemplates$: Observable<TemplatesRow[]> = null;
	private _templatesByService$: Observable<TemplatesRow[]> = null;

	#operators: Observable<OperatorData[]> = null;
	#teams$: Observable<TeamData[]> = null;
	#roles$: Observable<RoleData[]> = null;
	#permissions$: Observable<GetPermissionsResponse> = null;
	#contactCategories$: Observable<ContactCategory[]> = null;
	#ordersTags$: Observable<UiOrdersTagData[]> = null;
	#ordersTagsDictionary$: Observable<OrderTagsDictionary> = null;
	#documentsTypes$: Observable<{ [key in keyof typeof ETipoDocumento]?: DocumentType }> = null;

	#siteID: number;
    #operator: EOperatore;

	constructor(private backend: BackendService, private translate: TranslateService) {
		translate.onLangChange.subscribe((_) => {
			this._mealPlans$ = null;
			this._topLocations$ = null;
			this._packageRules$ = null;
			this._policies$ = null;
			this._beddings$ = null;
			this._servicesOffersTypes$ = null;
			this._serviceAdditionalData$ = null;
			this._serviceAdditionalDataType = null;
			this._extraDocs$ = null;
			this._phraseTags$ = null;
			this.#ordersTags$ = null;
			this.#ordersTagsDictionary$ = null;
			this._articlesSections$ = null;
			this._allTemplates$ = null;
			this._templatesByService$ = null;
		});
	}

	/**
	 * Impostazione one-shot dell'ID del sito
	 * @param siteID ID del sito
	 */
	public setSite(siteID: number) {
		if (!this.#siteID && this.#siteID !== 0) this.#siteID = siteID;
	}
    /**
     * ID del sito attuale
     */
    public get siteID(): number {
        return this.#siteID;
    }

    public setOperator(operator: EOperatore) {
        if (!this.#operator) this.#operator = operator;
    } 
    public get actualOperator() {
        return this.#operator;
    }

	public getLingua(): ELingua {
		switch (this.translate.currentLang) {
			case 'en':
				return ELingua.English;
			case 'fr':
				return ELingua.Francais;
			case 'de':
				return ELingua.Deutsch;
			case 'nl':
				return ELingua.Dutch;
			case 'es':
				return ELingua.Spanish;
			case 'pt':
				return ELingua.Portuguese;
		}
		return ELingua.Italiano;
	}

	/**
	 * Ottiene la stringa ISO della lingua correntemente selezionata
	 * @param lang Codice a 2 lettere opzionale della lingua. Se omesso usa quello corrente di TranslateService
	 */
	public getIsoLang(lang?: string): string {
		switch (lang ?? this.translate.currentLang) {
			case 'en':
				return 'en-GB';
			case 'fr':
				return 'fr-FR';
			case 'de':
				return 'de-DE';
			case 'nl':
				return 'nl-BE';
		}
		return 'it-IT';
	}

	/**
	 * Ottiene il codice lingua a 2 lettere dall'enumeratore
	 * @param lang Lingua (enum) da decodificare
	 */
	public getLangCodeFromLingua(lang: ELingua): string {
		switch (lang) {
			case ELingua.Deutsch:
				return 'de';
			case ELingua.Dutch:
				return 'nl';
			case ELingua.Francais:
				return 'fr';
			case ELingua.Italiano:
				return 'it';
			case ELingua.Portuguese:
				return 'pt';
			case ELingua.Spanish:
				return 'es';
			case ELingua.English:
			default:
				return 'en';
		}
	}

	/**
	 * Ottiene le common label in una lingua specifica
	 * @param lang Lingua specifica
	 */
	public async getLangCommonLables(lang: ELingua): Promise<CommonLabels> {
		const langCode = this.getLangCodeFromLingua(lang);
		const tc = await this.translate.getTranslation(langCode).toPromise();
		return new CommonLabels({
			giorni: tc['COMMON.GIORNI-GENERICO'],
			notti: tc['COMMON.NOTTI-GENERICO'],
			nonRimborsabile: tc['COMMON.NON-RIMBORSABILE'],
			pagamentoImmediato: tc['COMMON.PAGAMENTO-IMMEDIATO'],
			persone: tc['COMMON.PERSONE-GENERICO'],
			nomeGruppoTutti: tc['COMMON.NOME-GRUPPO-TUTTI'],
			dettaglioNoleggioFormat: tc['COMMON.DETTAGLIO-NOLEGGIO-FORMAT'],
			partenzaTrattaVolo: tc['COMMON.PARTENZA-TRATTA-VOLO'],
			tariffaTO: tc['COMMON.TARIFFA-TO'],
			emissioneEntroIl: tc['COMMON.EMISSIONE-ENTRO-IL'],
			tariffaAerea: tc['COMMON.TARIFFA-AEREA'],
			tasseTariffaAerea: tc['COMMON.TASSE-TARIFFA-AEREA'],
			penaleEuroSeAnnullataEntro: tc['COMMON.PENALE-SE-ANNULLATA-ENTRO'],
			voliBagaglioNonIncluso: tc['COMMON.VOLI-BAGAGLIO-NON-INCLUSO'],
			voliEmissione: tc['COMMON.VOLI-EMISSIONE'],
			voliSegmentoPartenza: tc['COMMON.VOLI-SEGMENTO-PARTENZA'],
			voliOpzioneAutocancellazione: tc['COMMON.VOLI-OPZIONE-AUTOCANCELLAZIONE'],
			voliTariffaTO: tc['COMMON.VOLI-TARIFFA-TO'],
			voliTariffaNDC: tc['COMMON.VOLI-TARIFFA-NDC'],
			voliTariffaIP: tc['COMMON.VOLI-TARIFFA-IP'],
			voliTasseTO: tc['COMMON.VOLI-TASSE-TO'],
			voliTasseIP: tc['COMMON.VOLI-TASSE-IP'],
			voliTasseNDC: tc['COMMON.VOLI-TASSE-NDC'],
			voliNotRef: tc['COMMON.VOLI-NOT-REF'],
			voliPagamentoImmediato: tc['COMMON.VOLI-PAGAMENTO-IMMEDIATO'],
			voliEconomy: tc['COMMON.VOLI-ECONOMY'],
			voliBusiness: tc['COMMON.VOLI-BUSINESS'],
			voliFirst: tc['COMMON.VOLI-FIRST'],
			voliItinerario: tc['COMMON.VOLI-ITINERARIO'],
			voliSoluzione: tc['COMMON.VOLI-SOLUZIONE'],
			voliTariffaModifica: tc['COMMON.VOLI-TARIFFA-MODIFICA'],
			voliTasseModifica: tc['COMMON.VOLI-TASSE-MODIFICA'],
			voliNoPnrTOTariffaModifica: tc['COMMON.VOLI-NOPNR-TO-TARIFFA-MODIFICA'],
			voliNoPnrIPTariffaModifica: tc['COMMON.VOLI-NOPNR-IP-TARIFFA-MODIFICA'],
			lingua: lang,
		})
	}

	public getCommonLabels(): CommonLabels {
		return new CommonLabels({
			giorni: this.translate.instant('COMMON.GIORNI-GENERICO'),
			notti: this.translate.instant('COMMON.NOTTI-GENERICO'),
			nonRimborsabile: this.translate.instant('COMMON.NON-RIMBORSABILE'),
			pagamentoImmediato: this.translate.instant('COMMON.PAGAMENTO-IMMEDIATO'),
			persone: this.translate.instant('COMMON.PERSONE-GENERICO'),
			nomeGruppoTutti: this.translate.instant('COMMON.NOME-GRUPPO-TUTTI'),
			dettaglioNoleggioFormat: this.translate.instant('COMMON.DETTAGLIO-NOLEGGIO-FORMAT'),
			partenzaTrattaVolo: this.translate.instant('COMMON.PARTENZA-TRATTA-VOLO'),
			tariffaTO: this.translate.instant('COMMON.TARIFFA-TO'),
			emissioneEntroIl: this.translate.instant('COMMON.EMISSIONE-ENTRO-IL'),
			tariffaAerea: this.translate.instant('COMMON.TARIFFA-AEREA'),
			tasseTariffaAerea: this.translate.instant('COMMON.TASSE-TARIFFA-AEREA'),
			penaleEuroSeAnnullataEntro: this.translate.instant('COMMON.PENALE-SE-ANNULLATA-ENTRO'),
			voliBagaglioNonIncluso: this.translate.instant('COMMON.VOLI-BAGAGLIO-NON-INCLUSO'),
			voliEmissione: this.translate.instant('COMMON.VOLI-EMISSIONE'),
			voliSegmentoPartenza: this.translate.instant('COMMON.VOLI-SEGMENTO-PARTENZA'),
			voliOpzioneAutocancellazione: this.translate.instant('COMMON.VOLI-OPZIONE-AUTOCANCELLAZIONE'),
			voliTariffaTO: this.translate.instant('COMMON.VOLI-TARIFFA-TO'),
			voliTariffaIP: this.translate.instant('COMMON.VOLI-TARIFFA-IP'),
			voliTariffaNDC: this.translate.instant('COMMON.VOLI-TARIFFA-NDC'),
			voliTasseTO: this.translate.instant('COMMON.VOLI-TASSE-TO'),
			voliTasseIP: this.translate.instant('COMMON.VOLI-TASSE-IP'),
			voliTasseNDC: this.translate.instant('COMMON.VOLI-TASSE-NDC'),
			voliNotRef: this.translate.instant('COMMON.VOLI-NOT-REF'),
			voliPagamentoImmediato: this.translate.instant('COMMON.VOLI-PAGAMENTO-IMMEDIATO'),
			voliEconomy: this.translate.instant('COMMON.VOLI-ECONOMY'),
			voliBusiness: this.translate.instant('COMMON.VOLI-BUSINESS'),
			voliFirst: this.translate.instant('COMMON.VOLI-FIRST'),
			voliItinerario: this.translate.instant('COMMON.VOLI-ITINERARIO'),
			voliSoluzione: this.translate.instant('COMMON.VOLI-SOLUZIONE'),
			voliTariffaModifica: this.translate.instant('COMMON.VOLI-TARIFFA-MODIFICA'),
			voliTasseModifica: this.translate.instant('COMMON.VOLI-TASSE-MODIFICA'),
			voliNoPnrTOTariffaModifica: this.translate.instant('COMMON.VOLI-NOPNR-TO-TARIFFA-MODIFICA'),
			voliNoPnrIPTariffaModifica: this.translate.instant('COMMON.VOLI-NOPNR-IP-TARIFFA-MODIFICA'),
			lingua: this.getLingua(),
		});
	}

	/**
	 * Dizionario dei tipi di documento con relativi metadati
	 */
	public get documentsTypes$(): Observable<{ [key in keyof typeof ETipoDocumento]?: DocumentType }> {
		if (!this.#documentsTypes$) {
			this.#documentsTypes$ = this.backend.call('data/doctypes').pipe(
				map((data: GetDocumentTypesResponse) => data.documentTypes),
				shareReplay(1)
			);
		}
		return this.#documentsTypes$;
	}

	/**
	 * Dizionario dei tag delle offerte
	 */
	public get ordersTagsDictionary$(): Observable<OrderTagsDictionary> {
		if (!this.#ordersTagsDictionary$) {
			this.#ordersTagsDictionary$ = this.ordersTags$.pipe(
				map((ot) => {
					const result: OrderTagsDictionary = {};
					ot.forEach((t) => {
						result[t.id] = t;
					});
					return result;
				}),
				shareReplay(1)
			);
		}
		return this.#ordersTagsDictionary$;
	}

	/**
	 * Elenco dei tag delle offerte
	 */
	public get ordersTags$(): Observable<UiOrdersTagData[]> {
		if (!this.#ordersTags$) {
			this.#ordersTags$ = this.backend.call('data/orders_tags').pipe(
				map((data: GetOrdersTagsResponse) => {
					return (data.tags || []).map((t) => {
						const uiOptions = t.uiOptions ? (JSON.parse(t.uiOptions) as TagUiOptions) : null;
						return {
							id: t.id,
							tag: t.tag,
							backgroundColor: uiOptions?.backgroundColor,
							color: uiOptions?.color,
							iconClass: uiOptions?.iconClass,
						} as UiOrdersTagData;
					});
				}),
				shareReplay(1)
			);
		}
		return this.#ordersTags$;
	}

	/**
	 * Elenco dei permessi utente
	 */
	public get permissions$(): Observable<GetPermissionsResponse> {
		if (!this.#permissions$) {
			this.#permissions$ = this.backend.call('data/permissions').pipe(shareReplay(1));
		}
		return this.#permissions$;
	}

	/**
	 * Elenco delle categorie dei contatti
	 */
	public get contactCategories$(): Observable<ContactCategory[]> {
		if (!this.#contactCategories$) {
			const request: ISiteRequest = {
				lingua: this.getLingua(),
				siteID: this.siteID,
			};
			this.#contactCategories$ = this.backend.call('data/contact_categories', request).pipe(
				map((data: GetContactCategoriesResponse) => {
					return data.categories;
				}),
				shareReplay(1)
			);
		}
		return this.#contactCategories$;
	}

	public get roles$(): Observable<RoleData[]> {
		if (!this.#roles$) {
			this.#roles$ = this.backend.call('data/roles').pipe(
				map((data: GetRolesResponse) => {
					return data.roles;
				}),
				shareReplay(1)
			);
		}
		return this.#roles$;
	}

	/**
	 * Elenco completo dei team
	 */
	public get teams$(): Observable<TeamData[]> {
		if (!this.#teams$) {
			this.#teams$ = this.backend.call('data/teams').pipe(
				map((data: GetTeamsResponse) => {
					return data.teams;
				}),
				shareReplay(1)
			);
		}
		return this.#teams$;
	}

	/**
	 * Elenco dei team attivi (Privati o Pubblici)
	 */
	public get activeTeams$(): Observable<TeamData[]> {
		return this.teams$.pipe(
			map((teams) =>
				teams.filter(
					(t) => t.visibilita === EVisibilitaTeam.Privato || t.visibilita === EVisibilitaTeam.Pubblico
				)
			)
		);
	}

	/**
	 * Elenco dei team pubblici
	 */
	public get publicTeams$(): Observable<TeamData[]> {
        if (this.#operator == null) {
		    return this.teams$.pipe(map((teams) => teams.filter((t) => t.visibilita === EVisibilitaTeam.Pubblico)));
        } else {
            return this.teams$.pipe(map((teams) => teams.filter((t) => t.visibilita === EVisibilitaTeam.Pubblico && t.operatore === this.#operator)));
        }
	}

	public get operators$(): Observable<OperatorData[]> {
		if (!this.#operators) {
			this.#operators = this.backend.call('data/operators').pipe(
				map((data: GetOperatorsResponse) => {
					return data.operators;
				}),
				shareReplay(1)
			);
		}
		return this.#operators;
	}

	public get currencies$(): Observable<ValutaCorrente[]> {
		if (!this._currencies$) {
			this._currencies$ = this.backend.call('data/valute').pipe(
				map((data) => {
					return data.valute;
				}),
				shareReplay(1)
			);
		}
		return this._currencies$;
	}

	public get contractPayments$(): Observable<ModalitaPagamento[]> {
		if (!this._contractPayments$) {
			this._contractPayments$ = this.backend.call('data/modalitapagamento').pipe(
				map((data) => {
					return data.modalitaPagamento;
				}),
				shareReplay(1)
			);
		}
		return this._contractPayments$;
	}

	public get banks$(): Observable<Banca[]> {
		if (!this._banks$) {
			this._banks$ = this.backend.call('data/banche').pipe(
				map((data) => {
					return data.banche;
				}),
				shareReplay(1)
			);
		}
		return this._banks$;
	}

	public get fornitori$(): Observable<Fornitore[]> {
		if (!this._fornitori$) {
			this._fornitori$ = this.backend.call('data/fornitori').pipe(
				map((data) => {
					return data.fornitori;
				}),
				shareReplay(1)
			);
		}

		return this._fornitori$;
	}

	public get systemEmails$(): Observable<SystemEmailRow[]> {
		if (!this._systemEmails$) {
			this._systemEmails$ = this.backend.call('data/email').pipe(
				map((data) => {
					return data.emails;
				}),
				shareReplay(1)
			);
		}

		return this._systemEmails$;
	}

	public get beddings$(): Observable<SistemazioneListRow[]> {
		if (!this._beddings$) {
			const request: ISiteRequest = {
				lingua: this.getLingua(),
			};
			this._beddings$ = this.backend.call('data/sistemazioni', request).pipe(
				map((data) => {
					return data.sistemazioni;
				}),
				shareReplay(1)
			);
		}

		return this._beddings$;
	}

	public get companies$(): Observable<CompagniaAerea[]> {
		if (!this._companies$) {
			this._companies$ = this.backend.call('data/compagnie').pipe(
				map((data) => {
					return data.compagnie;
				}),
				shareReplay(1)
			);
		}

		return this._companies$;
	}

	public get airports$(): Observable<Aeroporto[]> {
		if (!this._airports$) {
			this._airports$ = this.backend.call('data/aeroporti').pipe(
				map((data) => {
					return data.aeroporti;
				}),
				shareReplay(1)
			);
		}

		return this._airports$;
	}

	public get flightStatues$(): Observable<StatoVolo[]> {
		if (!this._flightStatues$) {
			this._flightStatues$ = this.backend.call('data/stativolo').pipe(
				map((data) => {
					return data.stati;
				}),
				shareReplay(1)
			);
		}

		return this._flightStatues$;
	}

	public get mealPlans$(): Observable<TrattamentoTradotto[]> {
		if (!this._mealPlans$) {
			const request: ISiteRequest = {
				siteID: 0,
				lingua: this.getLingua(),
			};

			this._mealPlans$ = this.backend.call('data/trattamenti', request).pipe(
				map((data) => {
					return data.trattamenti;
				}),
				shareReplay(1)
			);
		}

		return this._mealPlans$;
	}

	public get advertiseImages$(): Observable<ImmaginePubblicitaria[]> {
		if (!this._advertiseImages$) {
			const request: ISiteRequest = {
				siteID: 1,
				lingua: this.getLingua(),
			};

			this._advertiseImages$ = this.backend.call('data/immaginipubblicitarie', request).pipe(
				map((data) => {
					return data.immagini;
				}),
				shareReplay(1)
			);
		}

		return this._advertiseImages$;
	}

	public get topLocations$(): Observable<LuogoTop[]> {
		if (!this._topLocations$) {
			const request: ISiteRequest = {
				siteID: 1,
				lingua: this.getLingua(),
			};

			this._topLocations$ = this.backend.call('data/luoghitop', request).pipe(
				map((data) => {
					return data.luoghiTop;
				}),
				shareReplay(1)
			);
		}

		return this._topLocations$;
	}

	public async getNearbyLocations(siteID: number, luogoID: number): Promise<LuogoVicino[]> {
		const request: IGetNearbyPlacesRequest = {
			siteID,
			lingua: this.getLingua(),
			luogoID,
		};
		const response: GetNearbyPlacesResponse = await this.backend.post('maps/nearby', request);
		return response.luoghiVicini;
	}

	public get articleHeads$(): Observable<ArticoloTestataRow[]> {
		if (!this._articleHeads$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._articleHeads$ = this.backend.call('data/intestazioniarticoli', request).pipe(
				map((data: GetArticleHeadsResponse) => {
					return data.intestazioni;
				}),
				shareReplay(1)
			);
		}

		return this._articleHeads$;
	}

	public get sites$(): Observable<Site[]> {
		if (!this._sites$) {
			const request: IApiRequest = {};

			this._sites$ = this.backend.call('websites/get', request).pipe(
				map((data: GetWebsitesResponse) => {
					return data.siti;
				}),
				shareReplay(1)
			);
		}

		return this._sites$;
	}

	public get packageRules$(): Observable<RegolaPacchettoRow[]> {
		if (!this._packageRules$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._packageRules$ = this.backend.call('data/regolepacchetti', request).pipe(
				map((data: GetPackageRulesResponse) => {
					return data.regole;
				}),
				shareReplay(1)
			);
		}

		return this._packageRules$;
	}

	public get policies$(): Observable<PenaleRow[]> {
		if (!this._policies$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._policies$ = this.backend.call('data/policies', request).pipe(
				map((data: GetPoliciesListResponse) => {
					return data.penali;
				}),
				shareReplay(1)
			);
		}

		return this._policies$;
	}

	public get servicesOffersTypes$(): Observable<ServiceOfferTypeRow[]> {
		if (!this._servicesOffersTypes$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._servicesOffersTypes$ = this.backend.call('data/services/offerstypes', request).pipe(
				map((data: GetServicesOffersTypesResponse) => {
					return data.offersTypes;
				}),
				shareReplay(1)
			);
		}

		return this._servicesOffersTypes$;
	}

	public get serviceAdditionalData$(): Observable<DatoAggiuntivoTipoRow[]> {
		if (!this._serviceAdditionalData$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._serviceAdditionalData$ = this.backend.call('data/svcdata', request).pipe(
				map((data: GetDatiAggiuntiviServiziResponse) => {
					return data.tipiDatoAggiuntivo;
				}),
				shareReplay(1)
			);
		}

		return this._serviceAdditionalData$;
	}

	// public get serviceAdditionalDataType$(): Observable<AdditionalDataTypeRow[]> {
	// 	if (!this.serviceAdditionalDataType$) {
	// 		const request: ILocalizedRequest = {
	// 			lingua: this.getLingua(),
	// 		};

	// 		this._serviceAdditionalDataType$ = this.backend.call('data/svcdata', request).pipe(
	// 			map((data: AdditionalsDataTypesGetResponse) => {
	// 				return data.additionalDataType;
	// 			}),
	// 			shareReplay(1)
	// 		);
	// 	}

	// 	return this._serviceAdditionalDataType$;
	// }

	public get extraDocs$(): Observable<GetExtraDocumentsResponse> {
		if (!this._extraDocs$) {
			const request: ISiteRequest = {
				lingua: this.getLingua(),
				siteID: 1,
			};

			this._extraDocs$ = this.backend.call('data/extradocs', request).pipe(
				map((data: GetExtraDocumentsResponse) => {
					return data;
				}),
				shareReplay(1)
			);
		}

		return this._extraDocs$;
	}

	private _miniguide$: Observable<GetMiniguideResponse> = null;
	public get miniguide$(): Observable<GetMiniguideResponse> {
		return this.getMiniguideByOfferta();
	}

	public getMiniguideByOfferta(offertaId: number = 0): Observable<GetMiniguideResponse> {
		if (!this._miniguide$) {
			const request: IMiniguideRequest = {
				lingua: this.getLingua(),
				offertaId: offertaId,
			};

			this._miniguide$ = this.backend.call('data/miniguide', request).pipe(
				map((data: GetMiniguideResponse) => {
					return data;
				}),
				shareReplay(1)
			);
		}

		return this._miniguide$;
	}

	public get phraseTags$(): Observable<TagView[]> {
		if (!this._phraseTags$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._phraseTags$ = this.backend.call('data/tags/phrases', request).pipe(
				map((data: GetPhraseTagsResponse) => {
					return data.tags;
				}),
				shareReplay(1)
			);
		}

		return this._phraseTags$;
	}

	public get articlesSections$(): Observable<SectionsRow[]> {
		if (!this._articlesSections$) {
			const request: ILocalizedRequest = {
				lingua: this.getLingua(),
			};

			this._articlesSections$ = this.backend.call('articles/get/sections', request).pipe(
				map((data: GetAllSectionsResponse) => {
					return data.sezioni;
				}),
				shareReplay(1)
			);
		}

		return this._articlesSections$;
	}

	public get allTemplates$(): Observable<TemplatesRow[]> {
		if (!this._allTemplates$) {
			const request: IGetTemplatesRequest = {
				lingua: this.getLingua(),
				servizioID: null,
			};

			this._allTemplates$ = this.backend.call('articolitemplate/templates/getall', request).pipe(
				map((data: GetAllTemplatesResponse) => {
					return data.template;
				}),
				shareReplay(1)
			);
		}

		return this._allTemplates$;
	}

	public templatesByService$(serviceId: number): Observable<TemplatesRow[]> {
		const request: IGetTemplatesRequest = {
			lingua: this.getLingua(),
			servizioID: serviceId,
		};

		this._templatesByService$ = this.backend.call('articolitemplate/templates/getall', request).pipe(
			map((data: GetAllTemplatesResponse) => {
				return data.template;
			}),
			shareReplay(1)
		);

		return this._templatesByService$;
	}

	public getDefaultSectionPhrases$(sectionId: number, serviceId?: number): Observable<TranslateArticleSection> {
		const request: ITextsGetFrasiDefaultRequest = {
			lingua: this.getLingua(),
			sezioneID: sectionId,
			luogoID: null,
			servizioID: serviceId,
		};

		const sub = this.backend.call('ArticoliTraducibili/texts/getfrasidefaultsezione', request).pipe(
			map((data: TextsGetFrasiDefaultResponse) => {
				return data.section;
			}),
			shareReplay(1)
		);

		return sub;
	}

	public getDefaultSectionItinerary$(serviceId?: number): Observable<TranslateArticleSection> {
		const request: ITextsGetItinerarioRequest = {
			lingua: this.getLingua(),
			servizioID: serviceId,
		};

		const sub = this.backend.call('ArticoliTraducibili/texts/getitinerary', request).pipe(
			map((data: TextsGetItinerarioResponse) => {
				return data.section;
			}),
			shareReplay(1)
		);

		return sub;
	}

	public async getServiceById$(serviceId?: number): Promise<ServiceData> {
		const request: IGetPublishedServiceRequest = {
			lingua: this.getLingua(),
			siteID: 1,
			servizioID: serviceId,
		};

		const response: IGetPublishedServiceResponse = await this.backend.post('services/get', request);
		return response.servizio;
	}

	public getAllStatistics$(): Observable<StatisticsRow[]> {
		const request: IStatisticsAllRequest = {
			lingua: this.getLingua(),
		};

		const sub = this.backend.call('statistics/all', request).pipe(
			map((data: IStatisticsAllResponse) => {
				return data.statistics;
			}),
			shareReplay(1)
		);

		return sub;
	}

	public async getFlightTicketsOfferta$(offertaID: number): Promise<VoloTickets[]> {
		const request: IGetFlightsTicketsRequest = {
			offertaID: offertaID
		};
		const response: IGetFlightsTicketsResponse = await this.backend.post('trip/getflighttickets', request);
		return response.tickets;
	}

	public async getTipologieServizio(servizioID: number): Promise<TipologiaListRow[]> {
		const request: IServizioGetRequest = {
			lingua: this.getLingua(),
			servizioID: servizioID
		};

		const response: IGetTipologieResponse = await this.backend.post('services/intranet/tipologie/get', request);
		return response.tipologie;
	}
}

